.overall-info {
  width: 85vw;
  height: min-content;
  /* border: 2px solid black; */
  text-align: center;
  width: 100%;
  /* padding-left: 15;
  padding-right: 15%; */
  max-width: 100%;
}

.overall-info p {
  padding-left: 7.5vw;
  padding-right: 7.5vw;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  justify-content: space-between;
  font-size: larger;
}

/* .info-box {
  data-sizing: intrinsic;
  width: 400px;
  height: max-content;
  border: 2px solid black;
  /* box-shadow: 10px 5px 5px black; */
  /* padding: 10px;
  margin: auto; */


.info-box img {
  margin: 5%;
  width: 350px;
  height: 350px;
  object-fit: cover;
}

.overall-div {
  background-color: white;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  margin: 0;
  vertical-align: top;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  max-width: 100%;
  min-height: 100vh;
  overflow:hidden;
}

.overall-div h2 {
  padding-left: 10vw;
  padding-right: 10vw;
}

.overall-div h3 {
  padding-left: 10vw;
  padding-right: 10vw; 
}

.overall-div h1 {
  padding-left: 10vw;
  padding-right: 10vw;
}

.overall-div-contact {
  background-color: lightgray;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh + 350px;
  margin: 0;
  vertical-align: top;
  max-width: 100%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.contact {
  display: inline-block;
  width: 31vw;
  height: max-content;
  text-align: center;
  vertical-align: bottom;
  padding: 1vw;
  margin: 5px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.contact h2 {
  padding:0;
  font-size: 2.2vw;
}

div.topImage {
  background-image: url(images/CoverPhoto.jpg);
  background-size: cover;
  width: 100%;
  height: 40vmin;
  vertical-align: top;
  object-fit: cover;
  opacity: 1;
  background-position: 50% 80%;
  display: flex;
  filter: saturate(1.25);
  max-width: 100%;
}

div.topImage:hover {
  opacity: 75%;
  transition: all 0.5s ease;
}

div.titleImage {
  opacity: 0%;
  width: 100%;
  padding: 0;
  text-align: center;
  align-items: center;
  display: flex;
  max-width: 100%;
}

div.titleImage h1 {
  font-weight: bold;
  font-size: 10vw;
  margin: auto;
  color: black;
  text-align:center;
  vertical-align: middle;
  height: min-content;
}

.titleImage:hover {
  opacity: 100%;
  transition: all 0.5s ease;
}

.nav-bar {
  vertical-align: top;
  width: auto;
  height: 6.9vh;
  width: 100%;
  background-color: darkgreen;
  background-size: cover;
  /* display: flex; */
  /* justify-content: space-evenly; */
  max-width: 100%;
}
.info-box-div {
  display: flex;
  justify-content: space-evenly;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: "darkseagreen" !important;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.nav-bar button {
  height: 6.9vh;
  width: 33.33vw;
  background-color: darkgreen;
  background-size: cover;
  border: transparent;
  opacity: 1;
  padding-left: 20px;
  margin: 0%;
  padding-right: 20px;
  font-size: x-large;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  cursor: pointer;
  color: black;
}

.nav-bar button:hover {
  background-color: lightgreen;
  transition: 0.25s ease;
}

.footer-div {
  /* display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto; */
  display: flex;
  flex-direction: column;

  width: 100%;
  /* position: absolute;
  bottom: 0; */
  height: min-content;
  vertical-align: bottom;
  border-top: 2px solid black;
  background-color: darkseagreen;
  background: cover;
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 100%;
  /* margin-top: 5vh */

}

.contactButton {
  height: 11.25vw;
  display: inline-block;
  width: 31vw;
  background-color: darkseagreen;
  /* border: 2px solid black; */
  border: transparent;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 2.2vw;
  font-weight: bold;
  color: black;
}

/* .footer-div form {
  padding-left: 5vw;
  padding-right: 5vw;
  background-color: darkseagreen;
  border-color: transparent;
  font-size: larger;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
} */

/* .footer-div button:hover {
  background-color: lightgreen;
} */

.beforeAfter {
  text-align: center;
  justify-content: space-evenly;
  width: 100%;
  height: max-content;
  margin-top: 3%;
  max-width: 100%;
}
.beforeAfter tr{
  border: 2px solid black;
}
.beforeAfter img {
  margin-top: 5%;
  margin-bottom: 5%;

  width: 35vw;
  height: 35vw;
  object-fit: cover;
  border: 2px solid black;
  filter: grayscale(0.75) saturate(1);
}

.beforeAfter img:hover {
  filter: grayscale(0) saturate(1);
  transition: all 1s ease;
}

.beforeAfter .before:hover {
  filter: grayscale(0);
  transition: all 1s ease;
}

.beforeAfter th {
  font-size: larger;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
}

.gallery {
  text-align: center;
}

.gallery h1 {
  margin-bottom: 10vw;
}

.gallery img {
  height: 24vw;
  width: 24vw;
  object-fit: cover;
  border: 1px solid black;
  margin-left: 2px;
  margin-right: 2px;
}

.rates-selector {
  /* border: 2px solid black; */
  width: max-content;
  /* padding: 3%; */
  /* margin: auto; */
  padding-left: 10vw;
  padding-right: 10vw;
}
label {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: xx-large;
}

.rates-selector select {
  display: block;
  margin: auto;
  width: auto;
  font-size: xx-large;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.rates-selector button {
  display: block;
  margin: auto;
  width: auto;
  font-size: xx-large;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    color: black;
}

.rates-selector label {
  display: block;
  margin: auto;
  width: auto;
  /* text-align: center; */
  padding-left: 0;
  padding-right: 0;
  padding-top: 2%;
}

.rates-selector input {
  display: inline;
  align-self: center;
  width: 75vw;
  font-size: xx-large;
  /* margin-top: 5%;
  margin-bottom: 5%; */
  background-color:lightgray;
  /* outline:none; */
}

.rates-selector input[type=text]:focus {
  background-color:darkseagreen;
  outline: black;
}
.rates-selector input[type=email]:focus {
  background-color:darkseagreen;
  outline: black;
}
.rates-selector input[type=tel]:focus {
  background-color:darkseagreen;
  outline: black;
}

.rates-selector button {
  display: block;
  margin: auto;
  width: auto;
  font-size: xx-large;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  margin-top: 5vh;
  margin-bottom: 5vh;
  background-color:rgb(18, 163, 18);
}

.three-pics {
  width: 25vw !important;
  height: 25vw !important;

  margin-top: 5%;
  margin-bottom: 5%;
  object-fit: cover;
  border: 2px solid black;
  filter: grayscale(0.75) saturate(1);
}

.four-pics {
  width: 20vw !important;
  height: 20vw !important;

  margin-top: 5%;
  margin-bottom: 5%;
  object-fit: cover;
  border: 2px solid black;
  filter: grayscale(0.75) saturate(1);
}

.rates-selector button:hover {
  background-color:lightgreen;
  transition: 0.25s ease;
  cursor: pointer;

}

.HomeLink {
  color: blue;
  text-decoration: underline;
}

.HomeLink:hover {
  cursor: pointer;
}



.contactButton:hover {
  cursor: pointer;
}

.ChrisPicture {
  width: 23vw;
  height: 23vw;
  float: right;
  margin-right: 10vw;
  border: 1px solid black;
  margin-left: 1.5vw;
  object-fit: cover;
}

.HomeText {
  text-align: justify;
  font-size: 1.5vmax;
  margin-top: 3vh;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.HomeText:first-of-type {
  margin-top: 10vh;
}

.homeSection:last-of-type {
  margin-bottom: 3vh;
}

.PageHeader {
  font-size: 4vw;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.galleryHeader {
  text-align: center;
  margin-left: 9vw;
  margin-right: 9vw;
  justify-content: center;
  font-size: 2vmax;
}

* {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.required {
  color: red;
}

.homeHeader {
  font-size: 2vmax;
}

.homeSection {
  font-size: 1.5vmax;
}